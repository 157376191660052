module.exports = [{
      plugin: require('/Users/michaelblythe/website-yellowmango/builtoncloud/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://builton.cloud"},
    },{
      plugin: require('/Users/michaelblythe/website-yellowmango/builtoncloud/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/michaelblythe/website-yellowmango/builtoncloud/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-33804535-2","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    }]
