// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-author-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-team-tsx": () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */)
}

exports.data = () => import("/Users/michaelblythe/website-yellowmango/builtoncloud/.cache/data.json")

